export const tableData = {
  Roi: [
    ["Duration", "16 weeks", "1-2 years", "1 year", "4-6 months"],
    [
      "Benefit",
      `Skills+ Placements from <br/>  ₹4.5-9L + Certification`,
      "Skills + Placement services from <span style='white-space: nowrap;'>₹4-9L</span> ",
      "Only Degree",
      "Only Certification",
    ],
    [
      "Fee",
      `₹50,000 + <br/> ₹25,000 for Placement track`,
      "₹5-10 Lakhs",
      "₹1.5-2 Lakhs",
      "₹30k-75k",
    ],
    [
      "Mode",
      "Online",
      `Offline<br/><span style="font-size: 12px; color:#4E4E4E; font-weight: 400;" >(incurs additional cost of lodging & travel)</span>`,
      "Online",
      "Online",
    ],
  ],
  Jobs: [
    [
      `Kinds of <br/> Placements`,
      `<span style='font-family:Plus Jakarta Sans;'>Full-Time job in top companies upto <br/> 9 LPA</span> `,
      " <span style='white-space: nowrap;'>Min. CTC undefined</span>",
      "N/A",
      " <span style='white-space: nowrap;'>Min. CTC undefined</span>",
    ],
    [
      `Placement Reports`,
      `Published since 2021 <br/> <a style='color: #F1AE13;' href="https://placement-reports.kraftshala.com/" target="_blank" >See here</a>.`,
      `No`,
      `No`,
      `No`,
    ],
    [
      "No. of Job Interviews",
      "No upper cap -  We keep going till you’re  placed",
      `Limited - <br/> Nothing specified`,
      `Limited - <br/> Nothing specified`,
      "Upper cap of 8 - Unclear policies",
    ],
  ],
  curriculum: [
    [
      "Curriculum",
      `Up-to-date frameworks,<br/>built with recruiters`,
      "Built with recruiters",
      `Built with universities-<br/> outdated`,
      "Limited",
    ],
    [
      "Tools",
      `Notion, ChatGPT, DAAL.E,<br/> Canva, Google Analytics <br/>  <a style='color: #F1AE13;' href="#csmTools" target="" > See full list here.</a>`,
      "Contains tools knowledge",
      "Generic tools",
      "Only ChatGPT, Canva, Notion",
    ],

    [
      "Projects",
      "Cases + Live Brand Briefs on  social, content, blogs etc.",
      "Brand Briefs",
      "Theoretical <br/> project briefs",
      "Theoretical <br/> project briefs",
    ],
  ],
  // network: [
  //   [
  //     "Peer Group",
  //     "Smart peers Acceptance rate is 15%",
  //     "Smart peers who’ve cleared CAT",
  //     "Anyone can enroll (no test)",
  //     "Anyone can enroll (namesake test)"
  //   ],
  //   [
  //     "Alumni Networks",
  //     "High on networking - 1700+ alumni, Meetups, Job networks",
  //     "Good Network",
  //     "No",
  //     "No"
  //   ],
  //   [
  //     "Lifelong upgradation",
  //     "Access to all Kraftshala LIVE sessions +  alumni benefits",
  //     "No",
  //     "No",
  //     "No"
  //   ],
  // ],
};
